var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      !_vm.hideButton
        ? _c(
            "div",
            [
              _vm.tag === "b-button"
                ? _c(
                    "b-button",
                    {
                      attrs: { variant: _vm.variantBtn },
                      on: {
                        click: function ($event) {
                          _vm.isOpen = true
                        },
                      },
                    },
                    [
                      _c("i", { class: _vm.icon }),
                      _vm._v("  " + _vm._s(_vm.btnLabel) + " "),
                    ]
                  )
                : _vm._e(),
              _vm.tag === "b-dropdown-item"
                ? _c(
                    "b-dropdown-item",
                    {
                      on: {
                        click: function ($event) {
                          _vm.isOpen = true
                        },
                      },
                    },
                    [
                      _c("b-icon", { attrs: { icon: "envelope" } }),
                      _vm._v("  " + _vm._s(_vm.btnLabel) + " "),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "modal-share-subscription-solo",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            id: "modal-subscription-solo",
            "hide-footer": "",
            "hide-header": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            size: "md",
          },
          model: {
            value: _vm.isOpen,
            callback: function ($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen",
          },
        },
        [
          _c(
            "div",
            { staticClass: "content-list-solo" },
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "3rem",
                          "margin-left": "118px",
                          "margin-bottom": "-47px",
                        },
                      },
                      [_vm._v("+")]
                    ),
                    _c("p", { staticStyle: { "font-size": "3rem" } }, [
                      _vm._v(_vm._s(_vm.FormMSG(66, "SOLO"))),
                    ]),
                    _c("p", {
                      staticStyle: { "font-size": "2rem" },
                      domProps: {
                        innerHTML: _vm._s(_vm.FormMSG(96, "15€ / month")),
                      },
                    }),
                    _c("p", { staticStyle: { "font-size": "1.3rem" } }, [
                      _c("img", {
                        staticStyle: { width: "30px", height: "30px" },
                        attrs: {
                          src: require("../../assets/img/checkbox.jpeg"),
                        },
                      }),
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(2, "Unlimited projects")) +
                          "\n\t\t\t\t\t"
                      ),
                    ]),
                    _c("p", { staticStyle: { "font-size": "1.3rem" } }, [
                      _c("img", {
                        staticStyle: { width: "30px", height: "30px" },
                        attrs: {
                          src: require("../../assets/img/checkbox.jpeg"),
                        },
                      }),
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(3, "Custom exports")) +
                          "\n\t\t\t\t\t"
                      ),
                    ]),
                    _c("p", { staticStyle: { "font-size": "1.3rem" } }, [
                      _c("img", {
                        staticStyle: { width: "30px", height: "30px" },
                        attrs: {
                          src: require("../../assets/img/checkbox.jpeg"),
                        },
                      }),
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(4, "Invoice creation")) +
                          "\n\t\t\t\t\t"
                      ),
                    ]),
                    _c("p", { staticStyle: { "font-size": "1.3rem" } }, [
                      _c("img", {
                        staticStyle: { width: "30px", height: "30px" },
                        attrs: {
                          src: require("../../assets/img/checkbox.jpeg"),
                        },
                      }),
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(5, "Unlimited archiving")) +
                          "\n\t\t\t\t\t"
                      ),
                    ]),
                    _c("p", { staticStyle: { "font-size": "1.3rem" } }, [
                      _c("img", {
                        staticStyle: { width: "30px", height: "30px" },
                        attrs: {
                          src: require("../../assets/img/checkbox.jpeg"),
                        },
                      }),
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(6, "Support 24 / 7")) +
                          "\n\t\t\t\t\t"
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("p", {
                      staticStyle: { "font-size": "1.3rem" },
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.FormMSG(
                            99,
                            "Click here to access Solo+ for free "
                          )
                        ),
                      },
                    }),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-3" },
                [
                  _vm.$screen.width <= 567
                    ? _c(
                        "b-col",
                        { attrs: { sm: "12", md: "6" } },
                        [
                          !_vm.isPromotion
                            ? _c(
                                "router-link",
                                {
                                  staticClass:
                                    "subscription-dialog-button w-100 mb-3",
                                  attrs: { to: "/settings", tag: "button" },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(7, "Subscribe")) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.isPromotion
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "subscription-dialog-button w-100 mb-3",
                                  on: { click: _vm.handleSubscribeClick },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(7, "Subscribe")) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("b-col", { attrs: { sm: "12", md: "6" } }, [
                    _c(
                      "button",
                      {
                        staticClass: "subscription-dialog-button w-100",
                        attrs: { size: "sm" },
                        on: {
                          click: function ($event) {
                            _vm.isOpen = false
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(8, "Cancel")) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ]),
                  _vm.$screen.width > 567
                    ? _c(
                        "b-col",
                        { attrs: { sm: "12", md: "6" } },
                        [
                          !_vm.isPromotion
                            ? _c(
                                "router-link",
                                {
                                  staticClass:
                                    "subscription-dialog-button w-100 mb-3",
                                  attrs: { to: "/settings", tag: "button" },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(7, "Subscribe")) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.isPromotion
                            ? _c(
                                "button",
                                {
                                  staticClass:
                                    "subscription-dialog-button w-100 mb-3",
                                  on: { click: _vm.handleSubscribeClick },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(7, "Subscribe")) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-success",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(12, "Success!"),
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "ok-variant": "success",
            "ok-only": "",
          },
          on: { ok: _vm.handleClickSuccessSubscribe },
          model: {
            value: _vm.showModalSuccessSubscribe,
            callback: function ($$v) {
              _vm.showModalSuccessSubscribe = $$v
            },
            expression: "showModalSuccessSubscribe",
          },
        },
        [
          _vm._v(
            "\n\t\t" +
              _vm._s(
                _vm.FormMSG(
                  200,
                  "To finalize the activation of your Solo license, you must log in again"
                )
              ) +
              "\n\t"
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }